import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAA5ZW5MNfdyowxgmZ5vjR9JBQg2g5VDPY",
  authDomain: "intellitrade-api.firebaseapp.com",
  databaseURL: "https://intellitrade-api.firebaseio.com",
  projectId: "intellitrade-api",
  storageBucket: "intellitrade-api.appspot.com",
  messagingSenderId: "322261648213",
  appId: "1:322261648213:web:03ff79e2a609eb6cb7a5dc",
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
