import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./assets/index.css"
import App from "./App"

Sentry.init({
  dsn: "https://5815ff4224b9401da3c6126f8675c7c0@o488798.ingest.sentry.io/5597566",
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  tracesSampleRate: 0.2,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
