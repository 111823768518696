import React from "react";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Invite = (props: any) => {
    const referralId = props.match.params.referralId

    var date = new Date();
    date.setTime(date.getTime() + (15*24*60*60*1000));

    cookies.set('referralId', referralId, { path: '/', expires: date });

    window.open('https://intellitrade.app', '_self') //to open new page

    return (
        <p></p>
    );
};
  
export default Invite;