import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../components/Logo";
import { Link } from "react-router-dom";
import { auth } from "../firebase";
import { toast } from 'react-toast'
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import Cookies from 'universal-cookie';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  min-height: 100vh;

  .stripe-element-card {
    width: 450px;
    max-width: 90vw;
  }

  .error {
    border-bottom: 2px solid #ef4c4c;
    color: #ef4c4c;

    transition: 0.3s all ease-out 0s;

    &::placeholder {
      color: #ef4c4c;
    }
  }

  .active {
    background: linear-gradient(180deg, #45a5ff 0%, #007ff5 100%);
    box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.25);
    color: white;
    opacity: 1;
    transition: .3s;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 450px;
  max-width: 90vw;
  margin-top: auto;
  margin-bottom: 50px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Subheading = styled.span`
  margin-top: 15px;
  font-weight: 800;
  font-size: 19px;
  color: #666c6f;
`;

const InputField = styled.input`
  width: 450px;
  max-width: 90vw;
  margin-bottom: 40px;
  border: none;
  outline: none;
  background: none;
  padding-bottom: 12px;
  border-bottom: 2px solid rgba(102, 108, 111, 0.4);
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;

  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: rgba(102, 108, 111, 0.9);

  transition: 0.1s border-bottom ease-out 0s;

  &:focus {
    border-bottom: 2px solid #007ff5;
    color: rgba(102, 108, 111, 0.9) !important;
  }

  &::placeholder {
    color: rgba(102, 108, 111, 0.6);
  }
`;

const CardUnderline = styled.div`
  background: #008aed;
  border-radius: 1px;
  width: 450px;
  max-width: 90vw;
  height: 2px;
  margin-top: 10px;
`;

const OptionsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 450px;
  max-width: 90vw;
  margin-bottom: 30px;
  color: #9da3a5;
`;

const ResetPassword = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
`;

const SubmitButton = styled.span`
  background: rgba(102, 108, 111, 0.1);
  border-radius: 8px;
  text-align: center;
  width: 450px;
  max-width: 90vw;
  padding: 17px 0;
  cursor: pointer;
  font-weight: 800;
  font-size: 18px;
  color: #666c6f;
  mix-blend-mode: normal;
  opacity: 0.7;
  margin-bottom: 30px;
`;

const FinalSubmit = styled.span`
  background: linear-gradient(180deg, #45a5ff 0%, #007ff5 100%);
  box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  text-align: center;
  width: 450px;
  max-width: 90vw;
  padding: 17px 0;
  cursor: pointer;
  font-weight: 800;
  font-size: 18px;
  color: white;
  margin-bottom: 30px;


  .lds-ring {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SignUpText = styled.span`
  margin-bottom: auto;
  font-weight: 800;
  font-size: 18px;
  color: #666c6f;
`;

const CopyrightText = styled.span`
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #989898;
  margin-bottom: 15px;
`;

const Signup = (props: any) => {
  window.open('/login', '_self') //to open new page

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [signUpStep, setSignUpStep] = useState(1);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [errors, setErrors] = useState({
    fullName: false,
    email: false,
    password: false,
  });

  const showErrorMessage = (message: string) => toast.error(message);

  const stripe: any = useStripe();
  const elements: any = useElements();

  const cookies = new Cookies();

  const verifyFields = () => {
    let errorsFound = {
      fullName: false,
      email: false,
      password: false,
    };
    
    if (signUpStep === 1) {
      var emailRegex = /\S+@\S+\.\S+/;
      if (!emailRegex.test(email)) {
        errorsFound.email = true;
      }
      if (password.length <= 6) {
        errorsFound.password = true;
      }
      if (fullName.split(" ").length < 2) {
        errorsFound.fullName = true;
      }
    }
  };

  const submitHandler = async (e: React.SyntheticEvent) => {
    let errorsFound = {
      fullName: false,
      email: false,
      password: false,
    };
    if (signUpStep === 1) {
      var emailRegex = /\S+@\S+\.\S+/;
      if (!emailRegex.test(email)) {
        errorsFound.email = true;
      }
      if (password.length <= 6) {
        errorsFound.password = true;
      }
      if (fullName.split(" ").length < 2) {
        errorsFound.fullName = true;
      }

      if (
        !errorsFound.fullName &&
        !errorsFound.email &&
        !errorsFound.password
      ) {
        setSignUpStep(2);
      }

      setErrors(errorsFound);
    } else {
      setButtonLoader(true);

      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email,
          name: fullName
        }
      });

      if (error) {
        setButtonLoader(false);
        showErrorMessage(error.message || "There was an error processing your payment.");
      } else {
        const referralId = cookies.get('referralId');

        axios
          .post("https://api.intellitrade.app/v1/user/create", {
            email: email,
            name: fullName,
            password: password,
            paymentMethod: paymentMethod.id,
          }, {
            headers: {
              referral: referralId
            }
          })
          .then((res) => {
            console.log(res);

            if (res.data.success !== true) {
              showErrorMessage(res.data.message);
              setButtonLoader(false);
            } else {
              handleSuccessfulSignup();
            }
          })
          .catch((err) => {
            showErrorMessage("There was an error. Please try again.");
            setButtonLoader(false);
          });
      }
    }
  };

  const handleSuccessfulSignup = () => {
    auth
        .signInWithEmailAndPassword(email, password)
        .then((res) => {
          return res.user?.getIdToken();
        })
        .then((token) => {
          console.log(token);
          if (token) {
            localStorage.setItem("sessionToken", token);
            props.history.push("/dashboard");
            setButtonLoader(false);
          }
        })
        .catch((error) => {
          console.log(error.message);
          setButtonLoader(false);
          props.history.push("/login");
        });
  };

  const resetErrors = () => {
    setErrors({
      fullName: false,
      email: false,
      password: false,
    });
  };

  return (
    <Container>
      <Header>
        <Column>
          <Logo />
          <Subheading>
            Please enter{" "}
            {signUpStep === 1 ? "your details." : "your payment info."}
          </Subheading>
        </Column>
      </Header>
      {signUpStep === 1 ? (
        <InputField
          type="text"
          placeholder="Full Name"
          autoComplete="name"
          onChange={(e) => {
            setFullName(e.target.value);
            resetErrors();
            verifyFields();
          }}
          className={errors.fullName ? "error" : ""}
        />
      ) : (
        <></>
      )}
      {signUpStep === 1 ? (
        <InputField
          type="email"
          placeholder="Email"
          autoComplete="email"
          onChange={(e) => {
            setEmail(e.target.value);
            resetErrors();
            verifyFields();
          }}
          className={errors.email ? "error" : ""}
        />
      ) : (
        <></>
      )}
      {signUpStep === 1 ? (
        <InputField
          type="password"
          placeholder="Password"
          onChange={(e) => {
            setPassword(e.target.value);
            resetErrors();
            verifyFields();
          }}
          autoComplete="new-password"
          className={errors.password ? "error" : ""}
        />
      ) : (
        <></>
      )}
      {signUpStep === 2 ? (
        <CardElement
          className={"stripe-element-card"}
          options={{
            style: {
              base: {
                fontSize: "18px",
                color: "#424770",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />
      ) : (
        <></>
      )}
      {signUpStep === 2 ? <CardUnderline></CardUnderline> : <></>}
      <OptionsRow>
        {signUpStep === 2 ? (
          <ResetPassword style={{ marginTop: signUpStep === 2 ? "40px" : "0" }}>
            By signing up, you agree to {" "}
          <Link
              to="/terms"
              target="_blank"
            style={{ color: "#007FF5", textDecoration: "none" }}
          >
            Terms of Service
          </Link>
          </ResetPassword>          
        ) : (
        <ResetPassword style={{ marginTop: signUpStep === 2 ? "40px" : "0" }}>
          By signing up, you agree to {" "}
          <Link
                to="/terms"
                target="_blank"
            style={{ color: "#007FF5", textDecoration: "none" }}
          >
            Terms of Service
          </Link>
        </ResetPassword>
        )}
      </OptionsRow>
      {signUpStep === 1 ? (
        <SubmitButton
          onClick={submitHandler}
          className={"active"}
        >
          Next
        </SubmitButton>
      ) : (
        <FinalSubmit onClick={submitHandler}>{ buttonLoader && <div className="lds-ring"><div></div><div></div><div></div><div></div></div> || 'Sign Up For $1' }</FinalSubmit>
      )}
      <SignUpText>
        Already have an account?{" "}
        <Link to="/" style={{ color: "#007FF5", textDecoration: "none" }}>
          Sign In →
        </Link>
      </SignUpText>
      <CopyrightText>
        © 2021 IntelliTrade LLC. All rights reserved.
      </CopyrightText>
    </Container>
  );
};

export default Signup;
