import React, { useState } from "react";
import styled from "styled-components";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { toast } from 'react-toast'
import cardIcon from "../assets/card-popup-icon.svg";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(13.5914px);
  z-index: 4;

  display: flex;
  align-items: center;
  justify-content: center;

  .stripe-element-card {
    width: 450px;
    max-width: 90vw;
  }
`;

const Popup = styled.div`
  background: #f0f5f6;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;

  animation: 0.2s grow ease-out 0s;

  @keyframes grow {
    from {
      opacity: 0;
      transform: scale(0.3);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const Heading = styled.span`
  font-weight: 800;
  font-size: 28px;
  color: #212324;
  text-align: center;
  margin-bottom: 5px;
`;

const Subheading = styled.span`
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
  color: #666c6f;
`;

const CardUnderline = styled.div`
  background: #008aed;
  border-radius: 1px;
  width: 450px;
  max-width: 90vw;
  height: 2px;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const PrimaryAction = styled.span`
  cursor: pointer;
  background: linear-gradient(180deg, #45a5ff 0%, #007ff5 100%);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  color: white;
  padding: 18px 0;
  box-sizing: border-box;
  width: 45%;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const SecondaryAction = styled.span`
  cursor: pointer;
  background: linear-gradient(180deg, #ffffff 0%, #f6f6f6 99.95%);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  color: #212324;
  padding: 18px 0;
  box-sizing: border-box;
  width: 49%;
`;

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  userData: { name: string; email: string };
}

const EditModal = (props: ModalProps) => {
  const [buttonLoader, setButtonLoader] = useState(false);

  const showErrorMessage = (message: string) => toast.error(message);

  const stripe: any = useStripe();
  const elements: any = useElements();

  const handleContainerClick = (e: any) => {
    if (e.target.id === "background-space") {
      props.closeModal();
    }
  };

  const handleClose = () => {
    props.closeModal();
};

  const handleEdit = async () => {
    setButtonLoader(true);
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        email: props.userData.email,
        name: props.userData.name,
      },
    });

    if (paymentMethod.card.funding === 'prepaid') {
      showErrorMessage("You cannot pay with a prepaid debit card.");
      setButtonLoader(false);
      return;
    }

    if (error) {
      showErrorMessage(error.message);
      setButtonLoader(false);
    } else {
      const sessionToken = localStorage.getItem("sessionToken");
      if (sessionToken) {
        axios
          .put(
            "https://api.intellitrade.app/v1/subscription/payment",
            {
              paymentMethod: paymentMethod.id,
            },
            {
              headers: {
                session: sessionToken,
              },
            }
          )
          .then((res) => {
            if (res.data.success !== true) {
              showErrorMessage(res.data.message);
              setButtonLoader(false);
            } else {
              setButtonLoader(false);
              props.closeModal();
              window.location.reload(false);
            }
          })
          .catch((error) => {
            showErrorMessage(
              "There was an error, please try changing your payment method later."
            );
            setButtonLoader(false);
          });
      }
    }
  };

  return (
    <>
      {props.isOpen ? (
        <Container onClick={handleContainerClick} id={"background-space"}>
          <Popup>
            <img
              className={"icon"}
              src={cardIcon}
              alt={"Edit Payment Method"}
            />
            <Heading>Edit Payment Method</Heading>
            <Subheading>Please enter your payment info.</Subheading>
            <CardElement
              className={"stripe-element-card"}
              options={{
                style: {
                  base: {
                    fontSize: "18px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
            />
            <CardUnderline></CardUnderline>
            <Row>
              <PrimaryAction onClick={handleEdit}>
                {(buttonLoader && (
                  <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )) ||
                  "Save"}
              </PrimaryAction>
              <SecondaryAction onClick={handleClose}>Back</SecondaryAction>
            </Row>
          </Popup>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditModal;
