import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { toast } from 'react-toast'
import Logo from "../components/Logo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin-top: 40px;

  .ready {
    background: linear-gradient(180deg, #45a5ff 0%, #007ff5 100%);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    opacity: 1;
    color: white;
    transition: .3s;
  }
`;

const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 45px;
    height: 45px;
    margin: 8px;
    border: 3.5px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #007ff5 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 450px;
  max-width: 90vw;
  margin-top: auto;
  margin-bottom: 50px;

  @media only screen and (max-width: 1000px) {
    margin-top: 50px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Subheading = styled.span`
  margin-top: 15px;
  font-weight: 800;
  font-size: 19px;
  color: #666c6f;
`;

const CopyrightText = styled.span`
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #989898;
  margin-bottom: 15px;
  margin-top: 15px;
`;

const PostText = styled.textarea`
  width: 435px;
  height: 150px;
  max-width: 90vw;
  margin-bottom: 30px;
  border: none;
  outline: none;
  background: none;
  border: 3px solid rgba(102, 108, 111, 0.4);
  border-radius: 5px;
  resize: none;
  padding-left: 9px;
  padding-top: 9px;

  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;

  color: rgba(102, 108, 111, 0.9);

  &:focus {
    border: 3px solid #007ff5;
    color: rgba(102, 108, 111, 0.9) !important;
  }

  &::placeholder {
    color: rgba(102, 108, 111, 0.6);
  }
`

const PostType = styled.select`
  width: 455px;
  margin-bottom: 10px;
  height: 40px;
  border: none;
  outline: none;
  background: none;
  border: 3px solid rgba(102, 108, 111, 0.4);
  border-radius: 5px;
  resize: none;
  padding-left: 4px;
  padding-top: 5px;
  margin-bottom: 20px;

  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;

  color: rgba(102, 108, 111, 0.9);

  &:focus {
    border: 3px solid #007ff5;
    color: rgba(102, 108, 111, 0.9) !important;
  }
`

const MiniHeader = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: #212324;
  width: 450px;
  max-width: 90vw;
  margin-bottom: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: left;
  }
`;

const InputField = styled.input`
  width: 438px;
  height: 30px;
  max-width: 90vw;
  margin-bottom: 25px;
  border: none;
  outline: none;
  background: none;
  padding-left: 10px;
  padding-bottom: 5px;
  border: 3px solid rgba(102, 108, 111, 0.4);
  border-radius: 5px;

  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: rgba(102, 108, 111, 0.9);

  &:focus {
    border: 3px solid #007ff5;
    color: rgba(102, 108, 111, 0.9) !important;
  }

  &::placeholder {
    color: rgba(102, 108, 111, 0.6);
  }
`;

const SignOutButton = styled.span`
  cursor: pointer;
  background: linear-gradient(180deg, #e90059 0%, #e9002b 100%);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom: auto;
  width: 450px;
  max-width: 90vw;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding: 16px 0;

  @media only screen and (max-width: 1000px) {
    margin-bottom: 75px;
  }
`;

const SubmitButton = styled.span`
  background: rgba(102, 108, 111, 0.1);
  border-radius: 8px;
  text-align: center;
  width: 450px;
  max-width: 90vw;
  padding: 17px 0;
  cursor: pointer;
  font-weight: 800;
  font-size: 18px;
  color: #666c6f;
  mix-blend-mode: normal;
  opacity: 0.7;
  margin-bottom: 20px;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface DashboardProps {
  history: any;
}

interface UserData {
  name: string;
  email: string;
  uid: string;
  roles: [],
  subscription: {
    card: {
      last4: string;
      year: string;
      month: string;
      type: string;
    };
    customerId: string;
    renewalDate: string;
    subscriptionId: string;
  };
}

const Admin = (props: DashboardProps) => {
  const [userData, setUserData] = useState<UserData>();
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [body, setBody] = useState("");
  const [formatStatus, setFormatStatus] = useState(false);
  const [sendNotifications, setSendNotifications] = useState("yes");
  const [buttonLoader, setButtonLoader] = useState(false);

  const showSuccessMessage = (message: string) => toast.success(message);
  const showErrorMessage = (message: string) => toast.error(message);

  const signOutHandler = () => {
    localStorage.removeItem("sessionToken");
    props.history.push("/");
  };

  const handleSubmit = (e: React.SyntheticEvent<HTMLSpanElement>) => {
    const sessionToken = localStorage.getItem("sessionToken");

    if (title.length > 1 && type.length > 1 && sessionToken) {
      setButtonLoader(true);

      if (type === 'notification') {
        axios
          .post("https://api.intellitrade.app/v1/admin/notification/all", {
            type,
            title,
            message: body,
            price,
            sendNotifications
          },
            {
              headers: {
                session: sessionToken,
              }
            })
          .then((response) => {
            console.log(response);
            showSuccessMessage("Notifcation sent to all users");
            setButtonLoader(false);
          })
          .catch((error) => {
            console.log(error);
            showErrorMessage(
              "There was an error. Ask Conor what went wrong."
            );
            setButtonLoader(false);
          });
      } else {
        axios
          .post("https://api.intellitrade.app/v1/admin/create", {
            type,
            title,
            message: body,
            price,
            sendNotifications
          },
            {
              headers: {
                session: sessionToken,
              }
            })
          .then((response) => {
            console.log(response);
            showSuccessMessage("Post created, notifcations being sent.");
            setButtonLoader(false);
          })
          .catch((error) => {
            console.log(error);
            showErrorMessage(
              "There was an error. Ask Conor what went wrong."
            );
            setButtonLoader(false);
          });
      }
    } else {
      props.history.push("/");
    }
  };

  useEffect(() => {
    const sessionToken = localStorage.getItem("sessionToken");
    if (sessionToken) {
      axios
        .get("https://api.intellitrade.app/v1/user/get", {
          headers: {
            session: sessionToken,
          },
        })
        .then((response) => {
          if (response.data.success) {
            console.log(response.data.data);
            if (response.data.data?.roles?.indexOf('admin') === -1) {
              props.history.push("/");
              localStorage.removeItem("sessionToken");
              return;
            }
            setUserData(response.data.data);
          } else {
            props.history.push("/");
            localStorage.removeItem("sessionToken");
          }
        })
        .catch((error) => {
          props.history.push("/");
          localStorage.removeItem("sessionToken");
        });
    } else {
      props.history.push("/");
    }
  }, [props.history]);
  return (
    <>
      {userData ? (
        <Container>
          <Header>
            <Column>
              <Logo />
              <Subheading>{userData.email}</Subheading>
            </Column>
          </Header>
          <MiniHeader>Header Data</MiniHeader>
          <PostType onChange={e => setType(e.target.value)}>
            <option value="" hidden>Type</option>
            <option value="alert">Alert</option>
            <option value="sell">Sell</option>
            <option value="short">Short</option>
            <option value="buy">Buy</option>
            <option value="cover">Cover</option>
            <option value="notification">Mass Notification</option>
          </PostType>
          <InputField
            type="text"
            placeholder="Title"
            name="title"
            onChange={e => setTitle(e.target.value)}
          />
          <InputField
            type="text"
            placeholder="Price"
            name="price"
            onChange={e => setPrice(e.target.value)}
          />
          <MiniHeader>Body Text</MiniHeader>
          <PostText value={body} onChange={e => {
            if (formatStatus) {
              setBody(e.target.value);
              return;
            }

            const bodyText: String = e.target.value.replace(/ *\[[^)]*\] */g, '');
            const chunkArray = bodyText.split('(').join('~').split(')').join('~').split('~'); // Searches for links with format of [title](link)

            let modifiedBody = "";
            chunkArray.forEach((chunk: String) => {
              if (chunk.includes('http')) {
                chunk = `{${chunk}}`;
              }
              modifiedBody += chunk.replace(/\n$/, ''); // Replace function removes new line after {} image tags 
            })

            modifiedBody = modifiedBody.replace(/- /g, '\n- '); // Adds new line after bullet points (spaces them out)
            modifiedBody = modifiedBody.replace(/\n\n\n- /g, '\n\n- '); // Fixes double new line bug after </header> tag
            modifiedBody = modifiedBody.replace(/i> \n- /g, 'i> - '); // Removes new line after - in quote tag 

            setFormatStatus(true);
            setBody(modifiedBody);
          }
          }>
          </PostText>
          <MiniHeader>Send Notifications</MiniHeader>
          <Row>
            <div>
              <div>
                <label>
                  <input type="radio" value="yes"
                    checked={sendNotifications === 'yes'}
                    onChange={e => setSendNotifications(e.target.value)} />
              Yes
           </label>
              </div>
              <div>
                <label>
                  <input type="radio" value="no"
                    checked={sendNotifications === 'no'}
                    onChange={e => setSendNotifications(e.target.value)} />
              No
           </label>
              </div>
            </div>
          </Row>
          <SubmitButton onClick={handleSubmit} className={"ready"}>
            {buttonLoader && <div className="lds-ring"><div></div><div></div><div></div><div></div></div> || 'Create Post'}
          </SubmitButton>
          <SignOutButton onClick={signOutHandler}>Sign Out</SignOutButton>
          <CopyrightText>
            © 2021 IntelliTrade LLC. All rights reserved.
          </CopyrightText>
        </Container>
      ) : (
        <LoadingScreen>
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </LoadingScreen>
      )}
    </>
  );
};

export default Admin;
