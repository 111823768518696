import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import Logo from "../components/Logo";
import CancelModal from "../components/CancelModal";

// SVGs
import cardIcon from "../assets/card.svg";
import cancelIcon from "../assets/cancel.svg";
import appStoreIcon from "../assets/app-store.svg";
import googlePlayIcon from "../assets/google-play.svg";
import downloadArrow from "../assets/download-arrow.svg";

import EditModal from "../components/EditModal";
import { isAndroid } from "react-device-detect";

dayjs.extend(calendar);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin-top: 40px;

  .payment-link:nth-child(2n) {
    div {
      background: rgba(0, 0, 0, 0.02);
    }
  }
`;

const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 45px;
    height: 45px;
    margin: 8px;
    border: 3.5px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #007ff5 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 450px;
  max-width: 90vw;
  margin-top: auto;
  margin-bottom: 40px;

  @media only screen and (max-width: 1000px) {
    margin-top: 50px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Subheading = styled.span`
  margin-top: 15px;
  font-weight: 800;
  font-size: 19px;
  color: #666c6f;
`;

const CopyrightText = styled.span`
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #989898;
  margin-bottom: 15px;
  margin-top: 15px;
`;

const MiniHeader = styled.span`
  font-weight: 700;
  font-size: 18px;
  color: #212324;
  width: 450px;
  max-width: 90vw;
  margin-bottom: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;

const EditPayment = styled.div`
  background: linear-gradient(180deg, #45a5ff 0%, #007ff5 100%);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 215px;
  max-width: 90vw;
  margin-right: 25px;
  box-sizing: border-box;
  padding: 20px 0;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .heading {
    font-weight: 800;
    font-size: 18px;
    color: white;
    margin-top: auto;
  }

  .description {
    font-weight: bold;
    font-size: 16px;
    color: white;
    opacity: 0.8;
    text-transform: capitalize;
  }

  @media only screen and (max-width: 500px) {
    margin-right: 0;
    width: 90vw;
    margin-bottom: 10px;
  }
`;

const CancelSub = styled.div`
  background: linear-gradient(180deg, #ff136d 0%, #e9002b 100%);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 215px;
  max-width: 90vw;
  box-sizing: border-box;
  padding: 20px 0;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .heading {
    font-weight: 800;
    font-size: 18px;
    color: white;
  }

  .description {
    font-weight: bold;
    font-size: 16px;
    color: white;
    opacity: 0.8;
    text-transform: capitalize;
  }

  @media only screen and (max-width: 500px) {
    width: 90vw;
  }
`;

const Payments = styled.div`
  width: 450px;
  max-height: 250px;
  overflow: scroll;
  overflow-x: hidden;
  max-width: 90vw;
  border-radius: 8px;
  border: 1px solid #d7dcdd;
  margin-bottom: 30px;
`;

const PaymentRow = styled.div`
  background: rgba(0, 127, 245, 0.05);
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  overflow: auto;
`;

const PaymentAmount = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  color: #00b429;
  margin-right: 5px;
  margin-left: 4px;
`;

const PaymentDescription = styled.span`
  font-weight: bold;
  color: #212324;
`;

const PaymentDate = styled.span`
  color: #212324;
  font-weight: bold;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const SignOutButton = styled.span`
  cursor: pointer;
  background: linear-gradient(180deg, #e90059 0%, #e9002b 100%);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom: auto;
  width: 450px;
  max-width: 90vw;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding: 16px 0;

  @media only screen and (max-width: 1000px) {
    margin-bottom: 75px;
  }
`;

const DownloadButton = styled.div`
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #45a5ff 0%, #007ff5 100%);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 15px;
  box-sizing: border-box;
  width: 90vw;
  max-width: 450px;
  margin-bottom: 20px;

  div {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-right: auto;
  }
`;

const AppDownloadHeader = styled.span`
  font-weight: 800;
  font-size: 16px;
  color: white;
`;

const AppDownloadSubheader = styled.span`
  font-weight: bold;
  font-size: 10px;
  color: white;
  opacity: 0.8;
`;

interface DashboardProps {
  history: any;
}

interface InvoiceModel {
  hosted_invoice_url: string;
  currency: string;
  created: number;
  status_transitions: {
    paid_at: number;
  };
  lines: {
    data: {
      description: string;
    }[];
  };
  amount_paid: number;
}

interface UserData {
  name: string;
  email: string;
  uid: string;
  invoices: InvoiceModel[];
  roles: string[],
  subscription: {
    card: {
      last4: string;
      year: string;
      month: string;
      type: string;
    };
    customerId: string;
    renewalDate: string;
    subscriptionId: string;
  };
}

const Dashboard = (props: DashboardProps) => {
  const [userData, setUserData] = useState<UserData>();
  const [cancelModalOpen, setCancelModal] = useState(false);
  const [editModalOpen, setEditModal] = useState(false);

  const signOutHandler = () => {
    localStorage.removeItem("sessionToken");
    props.history.push("/");
  };

  useEffect(() => {
    const sessionToken = localStorage.getItem("sessionToken");
    if (sessionToken) {
      axios
        .get("https://api.intellitrade.app/v1/user/get", {
          headers: {
            session: sessionToken,
          },
        })
        .then((response) => {
          if (response.data.success) {
            console.log(response.data.data);
            setUserData(response.data.data);
          } else {
            props.history.push("/");
            localStorage.removeItem("sessionToken");
          }
        })
        .catch((error) => {
          props.history.push("/");
          localStorage.removeItem("sessionToken");
        });
    } else {
      props.history.push("/");
    }
  }, [props.history]);
  return (
    <>
      {userData ? (
        <Container>
          <CancelModal
            isOpen={cancelModalOpen}
            closeModal={() => setCancelModal(false)}
          />
          <EditModal
            isOpen={editModalOpen}
            closeModal={() => setEditModal(false)}
            userData={userData}
          />
          <Header>
            <Column>
              <Logo />
              <Subheading>{userData.email}</Subheading>
            </Column>
          </Header>
          <MiniHeader>Settings</MiniHeader>
          <Row>
            <EditPayment
              onClick={() => {
                setEditModal(true);
              }}
            >
              <img src={cardIcon} alt="Edit Payment" className="icon" />
              <span className="heading">Edit Payment</span>
              <span className="description">
                {userData.subscription.card.type}{" "}
                {userData.subscription.card.last4}
              </span>
            </EditPayment>
            <CancelSub
              onClick={() => {
                setCancelModal(true);
              }}
            >
              <img
                src={cancelIcon}
                alt="Cancel Subscription"
                className="icon"
              />
              <span className="heading">Cancel Subscription</span>
              <span className="description">
                Renews {userData.roles.includes("fnf") === true ? "Never (FNF)" : userData.subscription.renewalDate}
              </span>
            </CancelSub>
          </Row>
          <MiniHeader>Payments</MiniHeader>
          <Payments>
            {userData.invoices.map((invoice) => {
              return (
                <a
                  href={invoice.hosted_invoice_url}
                  key={invoice.status_transitions.paid_at}
                  style={{ textDecoration: "none" }}
                  className={"payment-link"}
                >
                  <PaymentRow>
                    <PaymentDescription>
                      {invoice.lines.data[0].description}
                    </PaymentDescription>
                    <PaymentDate>
                      {dayjs.unix(invoice.status_transitions.paid_at).format('MM/DD/YY')
                      }
                    </PaymentDate>
                    <PaymentAmount>
                      {invoice.currency === "usd" ? "$" : invoice.currency}
                      {invoice.amount_paid === 0 ? "0" : String(invoice.amount_paid/100)}
                    </PaymentAmount>
                  </PaymentRow>
                </a>
              );
            })}
          </Payments>
            <a
              href={isAndroid ? "https://play.google.com/store/apps/details?id=app.intellitrade.android" : "https://apps.apple.com/us/app/id1522378612"}
              style={{ textDecoration: "none" }}
            >
              <DownloadButton>
                <img
                  src={isAndroid ? googlePlayIcon : appStoreIcon}
                  alt={isAndroid ? "Google Play" : "App Store"}
                  className={"store-icon"}
                />
                <div>
                  <AppDownloadHeader>Download App</AppDownloadHeader>
                  <AppDownloadSubheader>
                    via {isAndroid ? "Google Play" : "App Store"}
                  </AppDownloadSubheader>
                </div>
                <img src={downloadArrow} alt="Download" />
              </DownloadButton>
            </a>

          <SignOutButton onClick={signOutHandler}>Sign Out</SignOutButton>
          <CopyrightText>
            © 2021 IntelliTrade LLC. All rights reserved.
          </CopyrightText>
        </Container>
      ) : (
        <LoadingScreen>
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </LoadingScreen>
      )}
    </>
  );
};

export default Dashboard;
