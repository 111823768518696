import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ToastContainer } from 'react-toast'
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Admin from "./pages/Admin";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Group from "./pages/Group";
import Invite from "./pages/Invite";

// Stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ForgotPassword from "./pages/ForgotPassword";
import Dashboard from "./pages/Dashboard";

const stripePromise = loadStripe(
  "pk_live_51H2KBSEeB2ekEuFf2X4hlGrGXgrCXoabzB7JKzPFa7lu82GWKVtR0rN6kOkO4TrqI0J1R1KioX5RVhak39VdFM9H00l0HBRPkg"
);

const App = () => {
  return (
    <div className={"App"}>
      <Router>
        <Route exact path={"/"} component={Login} />
        <Route exact path={"/login"} component={Login} />
        <Route exact path={"/reset"} component={ForgotPassword} />
        <Route exact path={"/admin"} component={Admin} />
        <Route exact path={"/privacy"} component={Privacy} />
        <Route exact path={"/terms"} component={Terms} />
        <Route exact path={"/group/:groupId"} component={Group} />
        <Route path={"/invite/:referralId"} component={Invite} />
        <Elements stripe={stripePromise}>
          <Route exact path={"/signup"} component={Signup} />
          <Route exact path={"/dashboard"} component={Dashboard} />
        </Elements>
      </Router>

      <ToastContainer delay={4000}/>
    </div>
  );
};

export default App;
