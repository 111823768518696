import React from "react";
import logoImage from "../assets/intellitrade-expanded-logo.svg";

const Logo = () => {
  return (
    <div style={{ position: "relative" }}>
      <img src={logoImage} width={290} alt={"Intellitrade Logo"} />
    </div>
  );
};

export default Logo;
