import React, { useEffect, useState } from "react";
import styled from "styled-components";
import giftIcon from "../assets/gift-icon.svg";
import axios from "axios";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-left: 10px;
  padding-right: 10px;

  .ready {
    background: linear-gradient(180deg, #45a5ff 0%, #007ff5 100%);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    opacity: 1;
    color: white;
    transition: 0.3s;
  }

  .active {
    background: linear-gradient(180deg, #45a5ff 0%, #007ff5 100%);
    box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.25);
    color: white;
    opacity: 1;
    transition: .3s;
  }
`;

const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 45px;
    height: 45px;
    margin: 8px;
    border: 3.5px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #007ff5 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Spacing = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 450px;
  max-width: 90vw;
  margin-top: auto;
  margin-bottom: 20px;
`;

const Heading = styled.span`
  font-weight: 800;
  font-size: 39px;
  text-align: center;
  color: #212324;
  margin-top: 20px;
`;

const SubText = styled.span`
  font-weight: 700;
  text-align: center;
  max-width: 600px;
  margin-top: 17px;
  font-size: 23px;
  color: #666c6f;
`;

const FreeMonthText = styled.span`
  font-weight: 800;
  text-align: center;
  font-size: 20px;
  color: #007FF5;
`;

const FreeMonthContainer = styled.div`
  margin-top: 25px; 
  text-align: center;
`;

const Image = styled.img`
  height: 110px;
  width 110px;
`;

const GroupLogos = styled.div`
`;

const SubmitButton = styled.span`
  background: rgba(102, 108, 111, 0.1);
  border-radius: 8px;
  text-align: center;
  width: 435px;
  max-width: 90vw;
  padding: 17px 0;
  cursor: pointer;
  font-weight: 800;
  font-size: 20px;
  color: #666c6f;
  mix-blend-mode: normal;
  opacity: 0.7;
  margin-bottom: 30px;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LearnMoreText = styled.span`
  margin-bottom: auto;
  font-weight: 800;
  font-size: 20px;
  color: #666c6f;
`;

const ClickHereLink= styled.a`
  color: #007FF5;
  text-decoration: none;
`;

interface GroupData {
  name: string;
  logo: string;
}

const Group = (props: any) => {
  const groupId = props.match.params.groupId

  const [groupData, setGroupData] = useState<GroupData>();

  useEffect(() => {
    axios.get(`https://api.intellitrade.app/v1/group/${groupId}`)
      .then((response) => {
        setGroupData(response.data.data)

        if (!response.data.data.name || !response.data.data.logo) {
          props.history.push("/signup");
        }
      })
      .catch((error) => {
        console.log(error);
        props.history.push("/signup");
      }); 
  }, [props.history]);

  const redirectToSignup = () => {
    props.history.push("/signup");
  }

  return (
    <>
    {groupData ? (
      <Container>

        <Spacing/>

        <GroupLogos>
          <Image src={groupData.logo}/>
          <Image src="https://firebasestorage.googleapis.com/v0/b/intellitrade-api.appspot.com/o/groups%2FIntelliTrade.png?alt=media&token=44f7d387-3636-4aed-a0fd-90c22cb553f4" style={{ marginLeft: 50 }}/>
        </GroupLogos>

        <FreeMonthContainer>
          <img alt="" src={giftIcon} style={{ fontSize: 27, height: 27, width: 27, marginRight: 15, verticalAlign: 'middle', marginBottom: 12 }}/>
          <FreeMonthText>
            Receive a 30 day trial on signup
          </FreeMonthText>
        </FreeMonthContainer>

        <Heading>
          Welcome, {groupData.name} members
        </Heading>
        <SubText>
          Welcome to IntelliTrade, an investment platform delivering professional investment ideas and insights to help make you money. Sign up below and get your first 30 days free.
        </SubText>

        <SubmitButton
          onClick={redirectToSignup}
          className={'active'}
          style={{
            marginTop: 40
          }}
        >
            Sign Up
        </SubmitButton>

        <LearnMoreText>
          Want to learn more?{" "}
          <ClickHereLink href="https://intellitrade.app">
            Click here →
          </ClickHereLink>
        </LearnMoreText>
        
      </Container>
      ) : (
        <LoadingScreen>
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </LoadingScreen>
      )}
    </>
  );
};

export default Group;
