import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { toast } from 'react-toast'
import cancelIcon from "../assets/cancel-icon.svg";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(13.5914px);
  z-index: 4;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Popup = styled.div`
  background: #f0f5f6;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;

  animation: 0.2s grow ease-out 0s;

  @keyframes grow {
    from {
      opacity: 0;
      transform: scale(0.3);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const Heading = styled.span`
  font-weight: 800;
  font-size: 28px;
  color: #212324;
  text-align: center;
  margin-bottom: 5px;
`;

const Subheading = styled.span`
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
  color: #666c6f;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const PrimaryAction = styled.span`
  cursor: pointer;
  background: linear-gradient(180deg, #e90059 0%, #e9002b 100%);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  color: white;
  padding: 18px 0;
  box-sizing: border-box;
  width: 45%;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SecondaryAction = styled.span`
  cursor: pointer;
  background: linear-gradient(180deg, #ffffff 0%, #f6f6f6 99.95%);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  color: #212324;
  padding: 18px 0;
  box-sizing: border-box;
  width: 49%;
`;

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const CancelModal = (props: ModalProps) => {
  const [buttonLoader, setButtonLoader] = useState(false);

  const showErrorMessage = (message: string) => toast.error(message);

  const handleContainerClick = (e: any) => {
    if (e.target.id === "background-space") {
      props.closeModal();
    }
  };

  const handleClose = () => {
      props.closeModal();
  };

  const handleCancel = () => {
    const sessionToken = localStorage.getItem("sessionToken");
    if (sessionToken) {
      setButtonLoader(true);

      axios
        .delete("https://api.intellitrade.app/v1/subscription", {
          headers: {
            session: sessionToken,
          },
        })
        .then((res) => {
          if (res.data.success !== true) {
            showErrorMessage(res.data.message);
            setButtonLoader(false);
          } else {
            setTimeout(function() {
              setButtonLoader(false);
              window.location.reload(false);          
            }, 1500);
          }
        })
        .catch((err) => {
          showErrorMessage(
            "There was an error, please email us to cancel your subscription."
          );
          setButtonLoader(false);
        });
    }
  };

  return (
    <>
      {props.isOpen ? (
        <Container onClick={handleContainerClick} id={"background-space"}>
          <Popup>
            <img
              className={"icon"}
              src={cancelIcon}
              alt={"Cancel Subscription"}
            />
            <Heading>Cancel Your Subscription?</Heading>
            <Subheading>Your account will be automatically deleted.</Subheading>
            <Row>
              <PrimaryAction onClick={handleCancel}>
                {(buttonLoader && (
                  <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )) ||
                  "Yes, Cancel"}
              </PrimaryAction>
              <SecondaryAction onClick={handleClose}>Back</SecondaryAction>
            </Row>
          </Popup>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

export default CancelModal;
