import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../components/Logo";
import { Link } from "react-router-dom";
import { toast } from 'react-toast'
import axios from "axios";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .ready {
    background: linear-gradient(180deg, #45a5ff 0%, #007ff5 100%);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    opacity: 1;
    color: white;
    transition: 0.3s;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 450px;
  max-width: 90vw;
  margin-top: auto;
  margin-bottom: 50px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Subheading = styled.span`
  margin-top: 15px;
  font-weight: 800;
  font-size: 19px;
  color: #666c6f;
`;

const InputField = styled.input`
  width: 450px;
  max-width: 90vw;
  margin-bottom: 40px;
  border: none;
  outline: none;
  background: none;
  padding-bottom: 12px;
  border-bottom: 2px solid rgba(102, 108, 111, 0.4);
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;

  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: rgba(102, 108, 111, 0.9);

  &:focus {
    border-bottom: 2px solid #007ff5;
    color: rgba(102, 108, 111, 0.9) !important;
  }

  &::placeholder {
    color: rgba(102, 108, 111, 0.6);
  }
`;

const SubmitButton = styled.span`
  background: rgba(102, 108, 111, 0.1);
  border-radius: 8px;
  text-align: center;
  width: 450px;
  max-width: 90vw;
  padding: 17px 0;
  cursor: pointer;
  font-weight: 800;
  font-size: 18px;
  color: #666c6f;
  mix-blend-mode: normal;
  opacity: 0.7;
  margin-bottom: 30px;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SignUpText = styled.span`
  margin-bottom: auto;
  font-weight: 800;
  font-size: 18px;
  color: #666c6f;
`;

const CopyrightText = styled.span`
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #989898;
  margin-bottom: 15px;
`;

const ForgotPassword = (props: any) => {
  const [email, setEmail] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);

  const showSuccessMessage = (message: string) => toast.success(message);
  const showErrorMessage = (message: string) => toast.error(message);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const handleSubmit = (e: React.SyntheticEvent<HTMLSpanElement>) => {
    if (email.length > 1) {
      setButtonLoader(true);

      axios
        .put("https://api.intellitrade.app/v1/user/reset", {
          email: email,
        })
        .then((response) => {
          console.log(response);
          showSuccessMessage("Password reset sent!");
          setButtonLoader(false);
        })
        .catch((error) => {
          console.log(error);
          showErrorMessage(
            "There is no user assigned to this email"
          );
          setButtonLoader(false);
        });
    }
  };

  return (
    <Container>
      <Header>
        <Column>
          <Logo />
          <Subheading>Reset password.</Subheading>
        </Column>
      </Header>
      <InputField
        type="email"
        name="email"
        placeholder="Email"
        onChange={handleEmailChange}
        onKeyPress={handleKeyPress}
      />
      <SubmitButton onClick={handleSubmit} className={"ready"}>
       {buttonLoader && <div className="lds-ring"><div></div><div></div><div></div><div></div></div> || 'Reset Password'} 
      </SubmitButton>
      <SignUpText>
        Have an account?{" "}
        <Link to="/" style={{ color: "#007FF5", textDecoration: "none" }}>
          Sign In →
        </Link>
      </SignUpText>
      <CopyrightText>
        © 2021 IntelliTrade LLC. All rights reserved.
      </CopyrightText>
    </Container>
  );
};

export default ForgotPassword;
